import { formatTime } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Card, CardContent, Divider, Grid, IconButton } from "@mui/material";
import {
  SignatureContainer,
  SignatureContainerRef,
} from "@src/app/shiftSignature/signatureContainer";
import { SelectedFile } from "@src/app/shiftSignature/timecard/model";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { Shift } from "@src/lib/interface";
import { TimeRange } from "@src/lib/interface/src";
import { useRef, useState } from "react";

import { useRevampWorkplaceSigningFlow } from "../useRevampWorkplaceSigningFlow";

type WorkplaceSignoutModalProps = {
  onSubmit: (file: SelectedFile) => void;
  onStrokeStarted: () => void;
  onBackClick: () => void;
  isUploadingImage: boolean;
  clockInOut: TimeRange;
  lunchInOut: TimeRange;
  facilityEmployeeName: string;
  shift: Shift;
};

export function WorkplaceSignoutModalContent(props: WorkplaceSignoutModalProps) {
  const {
    onSubmit,
    onStrokeStarted,
    onBackClick,
    isUploadingImage,
    lunchInOut,
    clockInOut,
    facilityEmployeeName,
    shift,
  } = props;
  const [taskCompleted, setTaskCompleted] = useState<string>();
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [showMainQuestion, setShowMainQuestion] = useState<boolean>(false);
  const [performance, setPerformance] = useState("");
  const [signature, setSignature] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  const signaturePad = useRef<SignatureContainerRef>(null);
  const { firstYesOrNoQuestion, mainQuestion } = useRevampWorkplaceSigningFlow();
  const workerName = shift.agent?.name;
  const facilityTimeZone = shift.facility?.tmz;

  function clearSignature() {
    signaturePad?.current?.clearSignature();
    setDisableSubmit(true);
  }

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {/* Sticky Header */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "background.paper",
          zIndex: 2,
          py: 2,
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <IconButton
          aria-label="Back"
          onClick={() => {
            clearSignature();
            onBackClick();
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Text variant="h3" ml={2}>
          {isDefined(workerName)
            ? `${facilityEmployeeName}, you are signing out ${workerName}, ${shift.agentReq}.`
            : `${facilityEmployeeName}, signing out worker`}
        </Text>
      </Box>

      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          pt: 4,
        }}
      >
        {/* Time entries */}
        <Card variant="outlined" sx={{ mb: 2 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                Clock-in
              </Grid>
              <Grid item xs={6} textAlign="right">
                {isDefined(clockInOut.start)
                  ? formatTime(clockInOut.start, { timeZone: facilityTimeZone })
                  : " --:--"}
              </Grid>
              <Grid item xs={6}>
                Break start
              </Grid>
              <Grid item xs={6} textAlign="right">
                {isDefined(lunchInOut.start)
                  ? formatTime(lunchInOut.start, { timeZone: facilityTimeZone })
                  : " --:--"}
              </Grid>
              <Grid item xs={6}>
                Break end
              </Grid>
              <Grid item xs={6} textAlign="right">
                {isDefined(lunchInOut.end)
                  ? formatTime(lunchInOut.end, { timeZone: facilityTimeZone })
                  : " --:--"}
              </Grid>
              <Grid item xs={6}>
                Clock-out
              </Grid>
              <Grid item xs={6} textAlign="right">
                {isDefined(clockInOut.end)
                  ? formatTime(clockInOut.end, { timeZone: facilityTimeZone })
                  : " --:--"}
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Divider />

        {/* Signature field */}
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Text variant="body1">Please sign below:</Text>
            <Button
              color="primary"
              size="small"
              onClick={clearSignature}
              sx={{
                textTransform: "none",
                minWidth: "auto",
                p: 0,
                color: "primary.main",
              }}
            >
              Clear
            </Button>
          </Box>
          <Box sx={{ border: "2px solid", borderColor: "divider", mb: 2 }}>
            <SignatureContainer
              ref={signaturePad}
              onChange={(updatedSignature: string) => {
                setSignature(updatedSignature);
                setDisableSubmit(!updatedSignature || updatedSignature === "");
              }}
              onStrokeStarted={() => onStrokeStarted()}
            />
          </Box>
        </Box>

        <Divider />

        {/* Task completion */}
        <Box>
          <Text mb={1}>{firstYesOrNoQuestion}</Text>
          <Box display="flex" gap={1}>
            {["Yes", "No", "I don't know"].map((label) => (
              <Button
                key={label}
                variant={taskCompleted === label ? "contained" : "outlined"}
                color="primary"
                fullWidth
                onClick={() => {
                  setTaskCompleted(label);
                  setShowMainQuestion(label !== "I don't know");
                  setPerformance("");
                  setShowError(false);
                }}
                size="small"
                sx={{ px: 0 }}
              >
                {label}
              </Button>
            ))}
          </Box>
        </Box>

        <Divider />

        {/* Performance assessment */}
        {showMainQuestion && (
          <Box>
            <Text mb={1} variant="subtitle1">
              {mainQuestion.description}
            </Text>
            <Box display="flex" flexDirection="column" gap={1}>
              {mainQuestion.options.map((option) => (
                <Button
                  key={option}
                  variant={performance === option ? "contained" : "outlined"}
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setPerformance(option);
                    setShowError(false);
                  }}
                  size="small"
                >
                  {option}
                </Button>
              ))}
            </Box>
          </Box>
        )}

        {showError && (
          <Text color="error" mt={1} variant="body2">
            *Please select an option
          </Text>
        )}

        {showMainQuestion && (
          <Text variant="body2" color="text.secondary">
            Your response is shared with the administrators and staffing coordinators at your
            facility.
          </Text>
        )}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ py: 1.5, mb: 2 }}
          onClick={() => {
            if (!isDefined(taskCompleted) || (showMainQuestion && !performance)) {
              setShowError(true);
              return;
            }

            const file = {
              file: signature,
              type: "png",
            };
            onSubmit(file);
            logEvent(APP_V2_USER_EVENTS.FEEDBACK_ON_HCP_WORK_QUALITY, {
              first_question: firstYesOrNoQuestion,
              first_response: taskCompleted,
              second_question: mainQuestion.description,
              second_response: performance,
              shiftId: shift._id,
              signedBy: facilityEmployeeName,
            });
          }}
          disabled={disableSubmit || isUploadingImage}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}

export default WorkplaceSignoutModalContent;
